import React from 'react';
import {withRouter} from 'react-router-dom';

import mixpanel from 'mixpanel-browser';
import {MixpanelProvider, MixpanelConsumer} from 'react-mixpanel';

import Routes from './routes';

import Header from "./header"
import Footer from "./footer"

import ScrollTop from "./_scrollTop.jsx"


class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.last_route = "";

        mixpanel.init('128ca38ac36741593893c9241940fb76');
    }

    render() {

        let route = this.props.location.pathname.split("/")[1]
        route = route === "" ? "index" : route

        if (this.last_route !== "" && route !== this.last_route) {
            window.scrollTo(0, 0)
        }


        this.last_route = route;

        return (
            <MixpanelProvider mixpanel={mixpanel}>
                <div className="page_wrapper" id={"page_" + route + "_wrapper"}>
                    <Header/>
                    <div className="content_wrapper" id={"content_" + route + "_wrapper"}>
                        <Routes/>
                    </div>
                    <Footer/>
                    <ScrollTop/>
                </div>
            </MixpanelProvider>
        );

    }
}

export default withRouter(Layout);
