import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {Modal} from "react-bootstrap";
import VisibilitySensor from "react-visibility-sensor";


import mobile_map from "../../images/mobile/quizz/map.png";
import {Link} from "react-router-dom";

const Map = forwardRef((props, ref) => {

    const [mapWidth, setMapWidth] = useState(620);

    const handleAreaClick = (event) => {
        //console.log(event.target.alt)
        ref.current.className = event.target.alt
        props.setAnswer(event.target.alt)
    }
    const handleMapSize = () => {

        //récupération de l'image + du mappage
        const img_map = document.getElementById('image-map')
        const img_map_area = img_map.getElementsByTagName('area')
        let coords = []

        for (let n = 0; n < img_map_area.length ; n++) {
            coords[n] = img_map_area[n].coords.split(',')
        }

        //calcul du ration de redimenssionnement de l'image
        const ratio = document.getElementById("map").width / mapWidth;

        //application du ratio au différentes area
        for (let n = 0; n < img_map_area.length; n++) {
            for (let m = 0; m < coords[n].length; m++) {
                coords[n][m] *= ratio;
            }
            img_map_area[n].coords = coords[n].join(',');
        }

        //enregistrement de la nouvelle taille de l'image
        setMapWidth(document.getElementById("map").width)

        //console.log(ratio,document.getElementById("map").width,mapWidth)
    }

    window.onload = handleMapSize
    window.resize = handleMapSize

    return (
        <>
            <div id={"map_wrapper"} ref={ref}>
                <img src={mobile_map} id={"map"} useMap="#image-map"/>
            </div>
            <map name="image-map" id="image-map">
                <area target="" alt="haut_de_france" title="hauts de france" href="#0"
                      coords="286,66,293,50,290,20,316,6,343,0,356,18,380,39,408,61,411,76,400,111,381,115,370,142,359,132,308,122,291,125,293,99,292,83"
                      shape="poly" onClick={handleAreaClick}/>
                <area target="" alt="grand_est" title="Grand est" href="#0"
                      coords="369,142,380,115,399,110,412,75,408,62,419,56,429,56,436,77,574,123,610,144,591,159,578,176,565,203,561,236,550,240,520,210,485,207,461,230,427,202,412,211,393,208,368,182"
                      shape="poly" onClick={handleAreaClick}/>
                <area target="" alt="normandie" title="Normandie" href="#0"
                      coords="133,94,147,155,176,161,181,166,201,158,214,169,229,169,250,185,255,157,274,147,280,131,289,126,290,96,292,83,282,68,255,85,233,88,217,96,221,115,198,114,168,99"
                      shape="poly" onClick={handleAreaClick}/>
                <area target="" alt="bretagne" title="Bretagne" href="#0"
                      coords="168,160,166,180,169,198,164,197,148,209,117,216,106,233,65,219,33,213,22,213,17,201,7,186,13,182,0,162,21,147,69,136,99,136,103,146,128,155,148,156"
                      shape="poly" onClick={handleAreaClick}/>
                <area target="" alt="pays_de_la_loire" title="pays de la loire" href="#0"
                      coords="169,161,167,178,168,195,162,198,148,209,116,217,105,234,128,250,127,276,136,300,148,316,170,320,182,310,164,266,211,260,221,227,241,225,253,210,248,186,228,168,215,171,200,158,183,166"
                      shape="poly" onClick={handleAreaClick}/>
                <area target="" alt="nouvelle_aquitaine" title="Nouvelle aquitaine" href="#0"
                      coords="148,319,156,329,151,352,155,364,116,530,125,535,175,551,194,526,181,499,184,489,236,474,269,417,300,417,313,390,322,384,326,336,307,316,261,311,254,289,210,259,165,266,181,309,169,319"
                      shape="poly" onClick={handleAreaClick}/>
                <area target="" alt="occitanie" title="Occitanie" href="#0"
                      coords="176,552,222,564,264,573,310,580,342,573,341,550,342,536,350,524,364,515,383,509,391,509,410,518,441,484,435,463,414,458,407,464,384,438,305,437,298,417,269,418,238,473,185,488,182,500,195,526"
                      shape="poly" onClick={handleAreaClick}/>
                <area target="" alt="ile_de_france" title="Ile de france" href="#0"
                      coords="277,148,300,183,316,184,324,196,346,196,355,184,369,183,369,143,359,132,308,121,292,122,281,128"
                      shape="poly" onClick={handleAreaClick}/>
                <area target="" alt="centre_val_de_loire" title="centre val de loire" href="#0"
                      coords="275,149,256,157,249,185,253,208,241,225,221,228,212,259,255,289,262,311,309,315,326,310,351,286,345,245,350,211,345,196,326,197,314,184,299,182"
                      shape="poly" onClick={handleAreaClick}/>
                <area target="" alt="bourgogne_franche_comte" title="Bourgogne franche comté" href="#0"
                      coords="350,285,381,295,396,312,395,326,411,329,420,320,435,327,438,311,484,320,497,311,503,315,514,297,535,275,560,255,564,251,560,237,550,241,521,209,484,207,461,229,426,202,411,211,393,207,369,183,356,183,346,195,351,209,344,244"
                      shape="poly" onClick={handleAreaClick}/>
                <area target="" alt="auvergne_rhone_alpes" title="Auvergne rhone alpes" href="#0"
                      coords="307,315,327,336,323,384,314,389,299,417,305,436,384,438,406,462,414,457,451,463,471,472,469,456,475,443,503,423,507,408,545,405,549,397,544,369,540,345,535,331,530,317,517,313,502,321,502,314,495,312,484,320,438,311,434,326,420,319,410,329,395,325,395,310,381,295,351,286,328,309,318,312"
                      shape="poly" onClick={handleAreaClick}/>
                <area target="" alt="paca" title="Provence alpes cote d'azur" href="#0"
                      coords="412,518,442,483,436,462,452,464,470,472,470,451,475,442,503,421,507,409,545,404,551,434,568,454,576,467,570,476,560,489,545,505,527,516,505,528,482,536,454,532,430,527"
                      shape="poly" onClick={handleAreaClick}/>
                <area target="" alt="corse" title="Corse" href="#0"
                      coords="584,510,578,541,550,548,537,590,557,642,582,650,592,634,600,600,610,583,594,512"
                      shape="poly" onClick={handleAreaClick}/>
            </map>
        </>
    );
});

const Quizz = () => {

    const [showStartModal, setShowStartModal] = useState(true);
    const [showReponseModal, setShowReponseModal] = useState(false);
    const [showEndModal, setShowEndModal] = useState(false);
    const [answer, setAnswer] = useState("");
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [questionCount, setQuestionCount] = useState(1);
    const [goodAnswer, setGoodAnswer] = useState(0);

    const [timerValue, setTimerValue] = useState(45);

    const [isCtaVisible, setIsCtaVisible] = useState(false);


    const timerRef = useRef();
    const timerScrollRef = useRef();
    const questionsTemp = useRef();
    const mapRef = useRef();

    //question / slug réponse / réponse / anecdote
    /*const questions = [
        ["ou est la région Hauts de france ?", "haut_de_france", "Hauts de france", "La région Hauts de france se trouve en Hauts de france, La bonne réponse est donc"],
        ["ou est la région Grand Est ?", "grand_est", "Grand Est", "La région Grand Est se trouve en Grand Est, La bonne réponse est donc"],
        ["ou est la région Normandie ?", "normandie", "Normandie", "La région Normandie se trouve en Normandie, La bonne réponse est donc"],
        ["ou est la région Bretagne ?", "bretagne", "Bretagne", "La région Bretagne se trouve en Bretagne, La bonne réponse est donc"],
        ["ou est la région Pays de la loire ?", "pays_de_la_loire", "Pays de la loire", "La région Pays de la loire se trouve en Pays de la loire, La bonne réponse est donc"],
        ["ou est la région Nouvelle Aquitaine ?", "nouvelle_aquitaine", "Nouvelle Aquitaine", "La région Nouvelle Aquitaine se trouve en Nouvelle Aquitaine, La bonne réponse est donc"],
        ["ou est la région Occitanie ?", "occitanie", "Occitanie", "La région Occitanie se trouve en Occitanie, La bonne réponse est donc"],
        ["ou est la région Ile de France ?", "ile_de_france", "Ile de France", "La région Ile de France se trouve en Ile de France, La bonne réponse est donc"],
        ["ou est la région Centre Val de Loire ?", "centre_val_de_loire", "Centre Val de Loire", "La région Centre Val de Loire se trouve en Centre Val de Loire, La bonne réponse est donc"],
        ["ou est la région Bourgogne Franche Comté ?", "bourgogne_franche_comte", "Bourgogne Franche Comté", "La région Bourgogne Franche Comté se trouve en Bourgogne Franche Comté, La bonne réponse est donc"],
        ["ou est la région Auvergne Rhone Alpes?", "auvergne_rhone_alpes", "Auvergne Rhone Alpes", "La région Auvergne Rhone Alpes se trouve en Auvergne Rhone Alpes, La bonne réponse est donc"],
        ["ou est la région PACA ?", "paca", "PACA", "La région PACA se trouve en PACA, La bonne réponse est donc"],
        ["ou est la région Corse ?", "corse", "Corse", "La région Corse se trouve en Corse, La bonne réponse est donc"]
    ];*/

    const questions = [
        ["Dans quelle région se situent les Gorges du Verdon, plus grand Canyon d'europe ?","paca","Provence-Alpes-Côte d'Azur"],
        ["Dans quelle région se fait-on le plus de bises pour se dire bonjour ?","corse","Corse"],
        ["Dans quelle région mange-t-on le plus de brioche ?","pays_de_la_loire","Pays de la Loire"],
        ["Dans quelle région fabrique-t-on le Chinois de La Fournée Dorée ?","grand_est","Grand-Est"],
        ["Dans quelle région est née la brioche ?","normandie","Normandie "],
        ["Dans quelle région pratique-t-on la danse de la brioche lors des mariages ?","pays_de_la_loire","Pays de la Loire"],
        ["Dans quelle région se situe le siège de La Fournée Dorée ?","pays_de_la_loire","Pays de la Loire"],
        ["Dans quelle ragion a été fabriquée la grande brioche de France, mesurant 117,55 mètres ?","pays_de_la_loire","Pays de la Loire"],
        ["Dans quelle région fabrique-t-on la Gâche de La Fournée Dorée ?","pays_de_la_loire","Pays de la Loire"],
        ["Quelle est la région la plus peuplée ?","ile_de_france","Île-de-France"],
        ["Quelle région est surnommée \"le jardin de la France\" ?","centre_val_de_loire","Centre-Val de Loire"],
        ["Quelle région est la plus grande en superficie ?","nouvelle_aquitaine","Nouvelle-Aquitaine"],
        ["Quelle région est la seule à avoir une frontière commune avec 4 pays différents ?","grand_est","Grand-Est"],
        ["Quelle région est la plus visitée par les touristes étrangers ?","paca","Provence-Alpes-Côte d'Azur"],
        ["Quelle région possède le plus grand nombre de sites classés au patrimoine mondial de l'UNESCO ?","auvergne_rhone_alpes","Auvergne-Rhône-Alpes"],
        ["Quelle région est connue pour sagastronomie à base de fromages et de charcuterie ?","auvergne_rhone_alpes","Auvergne-Rhône-Alpes"],
        ["Quelle région est la plus ancienne ?","bretagne","Bretagne"],
        ["Quelle région est la plus petite en superficie ?","corse","Corse"],
        ["Quelle région est la plus montagneuse ?","auvergne_rhone_alpes","Auvergne-Rhône-Alpes"],
        ["Quelle région est la seule à avoir une langue régionale officielle ?","corse","Corse"],
        ["Quelle est la région la moins peuplée ?","corse","Corse"],
        ["Quelle région est considérée comme la plus culturelle ?","ile_de_france","Île-de-France"],
        ["Quelle région est considérée comme la plus festive ?","bretagne","Bretagne"],
        ["Quelle région possède le plus de côtes maritimes ?","bretagne","Bretagne"],
        ["Dans quelle région a été portée la fameuse chemise à rayures pour la première fois ?","bretagne","Bretagne"],
        ["Quelle région est célèbre pour ses châteaux médiévaux ?","centre_val_de_loire","Centre-Val de Loire"],
        ["Dans quelle région se situe la ville dite rose ?","occitanie","Occitanie"],
        ["Dans quelle région sont nés les bateaux-mouches ?","auvergne_rhone_alpes","Auvergne-Rhône-Alpes"],
        ["Dans quelle régon se situe la plus longue rue piétonne d'Europe ?","nouvelle_aquitaine","Nouvelle-Aquitaine"],
        ["Dans quelle région a lieu la plus grande braderie de France ?","haut_de_france","Hauts-de-France"],
        ["De quelle région est originaire le Bretzel ?","grand_est","Grand-Est"],
        ["De quelle région partent les coureurs du Vendée Globe ?","pays_de_la_loire","Pays de la Loire"],
        ["Dans quelle région arrivent les coureurs du Tour de France ?","ile_de_france","Île-de-France"],
        ["Dans quelle région utilise-t-on le plus souvent le mot \"Chocolatine\" ?","nouvelle_aquitaine","Nouvelle-Aquitaine"],
        ["Dans quelle région le 26 décembre est également un jour férié ?","grand_est","Grand-Est"],
        ["Dans quelle région se trouve la commune qui possède le nom le plus court ?","haut_de_france","Hauts-de-France"],
        ["Dans quelle région se trouve le plus vieil arbre de France (un olivier) ?","paca","Provence-Alpes-Côte d'Azur"],
        ["Dans quelle région trouve-t-on le plus grand nombre de vaches par rapport au nombre d'habitants ?","normandie","Normandie "],
        ["Dans quelle région la raclette est considérée comme une spécialité culinaire ?","auvergne_rhone_alpes","Auvergne-Rhône-Alpes"],
        ["Dans quelle région le bœuf bourguignon est considéré comme une spécialité culinaire ?","bourgogne_franche_comte","Bourgogne-Franche-Comté"],
        ["Dans quelle région les crêpes sont considérées comme une spécialité culinaire ?","bretagne","Bretagne"],
        ["Dans quelle région la tarte tatin est considérée comme une spécialité culinaire ?","centre_val_de_loire","Centre-Val de Loire"],
        ["Dans quelle région la charcuterie est considérée comme une spécialité culinaire ?","corse","Corse"],
        ["Dans quelle région la choucroute est considérée comme une spécialité culinaire ?","grand_est","Grand-Est"],
        ["Dans quelle région les moules-frites sont considérées comme une spécialité culinaire ?","haut_de_france","Hauts-de-France"],
        ["Dans quelle région le macaron est considéré comme une spécialité culinaire ?","ile_de_france","Île-de-France"],
        ["Dans quelle région le cannelé est considéré comme une spécialité culinaire ?","nouvelle_aquitaine","Nouvelle-Aquitaine"],
        ["Dans quelle région le cassoulet est considéré comme une spécialité culinaire ?","occitanie","Occitanie"],
        ["Dans quelle région la bouillabaisse est considérée comme une spécialité culinaire ?","paca","Provence-Alpes-Côte d'Azur"]
    ];


    const selectQuestion = () => {
        const random = Math.floor(Math.random() * questionsTemp.current.length)
        const strate = document.getElementById("quizz");

        setCurrentQuestion(questionsTemp.current[random])
        questionsTemp.current.splice(random, 1)

        //démarrage du timer, si au bout de 3 secondes le cta n'est pas visible on scroll auto dessus
        if(isCtaVisible !== true){
            timerScrollRef.current = setTimeout(() => {

                window.scrollTo({
                    top :strate.getBoundingClientRect().bottom - window.innerHeight,
                    behavior : "smooth"
                });
            }, 3000);
        }
    }
    const startGame = () => {

        //start or reset the game
        setShowStartModal(false);
        setShowEndModal(false);

        //reset question array
        questionsTemp.current = questions;

        //reset everything
        setCurrentQuestion("");
        setAnswer("");
        setQuestionCount(1);
        setGoodAnswer(0);
        setTimerValue(45);
        mapRef.current.className = "";

        //set first question
        selectQuestion()
        startTimer()
    }
    const nextQuestion = () => {

        setShowReponseModal(false)

        mapRef.current.className = "";

        //finis
        if(questionCount >=5){
            setShowEndModal(true);
        }
        else{
            selectQuestion()
            setQuestionCount((questionCount) => questionCount+1)
            setAnswer("")
            startTimer()
        }
    }
    const submit = () => {

        if(answer === "")
            return;

        window.scrollTo(0,0)

        //pause timer
        pauseTimer()
        setShowReponseModal(true)

        if(answer === currentQuestion[1]){
            setGoodAnswer((goodAnswer) => goodAnswer+1)
        }
    }

    const startTimer = () => {
        //démmare le timer
        timerRef.current = setInterval( () => {
            setTimerValue(timerValue => timerValue - 1)
        }, 1000)
    }
    const pauseTimer = () => {
        clearInterval(timerRef.current);
    }

    useEffect(() => {
        return () => clearInterval(timerRef.current)
    },[])

    useEffect(() => {
        if(timerValue === 0){
            pauseTimer()
            setShowEndModal(true);
        }
    },[timerValue]);

    return (
        <>
            <Modal id="start_modal" backdrop="static" show={showStartModal} centered>
                <Modal.Body>
                    <a href={"#0"} onClick={startGame}></a>
                    <Link to={"/accueil"}></Link>
                </Modal.Body>
            </Modal>
            <Modal id="reponse_modal" backdrop="static" show={showReponseModal} centered>
                <Modal.Body className={`${answer === currentQuestion[1] ? 'good' : 'bad'}`}>
                    {answer === currentQuestion[1] && <div>Bonne réponse</div>}
                    {answer !== currentQuestion[1] &&
                        <>
                            <div>Mauvaise réponse</div>
                            <p>La bonne réponse est</p>
                            <div>“{currentQuestion[2]}”</div>
                        </>
                    }

                    <a href={"#0"} onClick={nextQuestion}>Valider</a>
                </Modal.Body>
            </Modal>
            <Modal id="end_modal" backdrop="static" show={showEndModal} centered>
                <Modal.Body>
                    <div className={"content"}>
                        <div className={goodAnswer === 0 ? "bad":"good"}>
                            {goodAnswer === 0 && "Dommage !"}
                            {goodAnswer === 1 && "Dommage !"}
                            {goodAnswer === 2 && "Pas mal !"}
                            {goodAnswer === 3 && "Bravo !"}
                            {goodAnswer === 4 && "Félicitation !"}
                            {goodAnswer === 5 && "Félicitation !"}
                        </div>
                        <p>
                            Vous avez eu&nbsp;
                            <span>
                                {goodAnswer}
                                {goodAnswer > 1 && " bonnes réponses, "}
                                {goodAnswer <= 1 && " bonne réponse, "}
                            </span>
                            <br/>
                            {goodAnswer === 0 && "retentez vite votre chance."}
                            {goodAnswer === 1 && "retentez vite votre chance."}
                            {goodAnswer === 2 && "retentez vite votre chance."}
                            {goodAnswer === 3 && "la prochaine sera la bonne."}
                            {goodAnswer === 4 && "la prochaine sera la bonne."}
                            {goodAnswer === 5 && "vous connaissez les régions de France par cœur."}
                        </p>
                    </div>
                    <a href={"#0"} onClick={startGame}></a>
                    <Link to={"/accueil"}></Link>
                </Modal.Body>
            </Modal>

            <div id={"quizz"} className={"strate header_global1 first last"}>

                <div className={"game_wrapper"}>
                    <div className={"game_part"}>
                    <div id={"timer"}>{timerValue} secondes</div>
                    <div id={"question"}>
                        <div>Question {questionCount}/5</div>
                        <div>{currentQuestion[0]}</div>
                    </div>
                    <a className={"cta d-none d-lg-block"} href={"#0"} onClick={submit}>Valider</a>
                </div>

                    <div className={"game_part"}>
                        <div id={"bulle"}/>

                        <Map setAnswer={setAnswer} ref={mapRef}/>
                    </div>

                    <div className={"text-center d-lg-none"}>
                        <VisibilitySensor onChange={(isVisible) => {
                            setIsCtaVisible(isVisible)
                            clearTimeout(timerScrollRef.current)
                        }}>
                            <a className={"cta"} href={"#0"} onClick={submit}>Valider</a>
                        </VisibilitySensor>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Quizz;
