import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';

const Footer = () => {

  return (
    <footer>

      <div id={"footer_wrapper"}>

        <div className={"footer_logo_wrapper"}>
          <div className={"footer_logo"}>
            <a id={"footer_logo_partenaire"} href={"https://www.lafourneedoree.fr/"} target={"_blank"}/>
            <a id={"footer_logo_tlc"} href={"https://www.tlcmarketing.com/france"} target={"_blank"}/>
          </div>
        </div>

        <div className={"footer_mention"}>
          <div>*Offre nominative, cumulable, limitée à 1 participation par foyer (même nom, même prénom et même adresse postale) et par adresse e-mail, valable pour l’achat de deux produits La Fournée Dorée porteurs de l’offre promotionnelle du 01/06/2023 au 30/07/2023 inclus (jusqu’à <br className={"d-lg-none"}/>épuisement des stocks). L’offre donne droit à deux activités offertes pour vos vacances. <a href="/file/Règlement.pdf" target={"_blank"}>Voir Règlement du Jeu complet</a>. Visuels non contractuels.</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
