import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Carousel} from "react-bootstrap";
import Contact from "./contact";

import VisibilitySensor from "react-visibility-sensor";

const Home = () => {

    const [isCarouselHowToVisible,setIsCarouselHowToVisible] = useState(false)
    const [isCarouselDotationToVisible,setIsCarouselDotationToVisible] = useState(false)

    const createCarouselHowTo = () => {

        const content = [
            [
                "Achetez, simultanément <br class='d-none d-lg-block'/>ou non, deux produits <br class='d-none d-lg-block'/>La fournée Dorée",
                [
                    "porteurs de l’offre entre le 1<sup>er</sup> juin et le 30 juillet 2023 inclus.",
                    "Un code unique figure sur chaque sticker collé sur tous les produits porteurs de l’offre. Conservez bien les deux codes uniques des deux produits porteurs de l’offre achetés, vous en aurez besoin pour enregistrer votre participation <a href='/je-participe' target='_blank'>ici</a> avant le 13 août 2023 23h59 (heure de connexion en France Métropolitaine faisant foi)."
                ]
            ],
            [
                "Rendez-vous sur <span>lafourneedoree-vacances.fr</span>",
                [
                    "et complétez le formulaire de participation en renseignant notamment vos deux codes uniques ainsi que vos coordonnées complètes. Précisez la catégorie ainsi que le nom des produits achetés porteurs de l’offre.",
                    "En participant à cette opération, et si votre participation est valide, vous serez automatiquement inscrit au tirage au sort pour tenter de gagner un séjour sur-mesure pour 4 personnes (2 adultes et 2 enfants) en France Métropolitaine d’une valeur maximale de 3 000€."
                ]
            ],
            [
                "Si votre participation est valide",
                [
                    "vous recevrez sous 2 semaines maximum un e-mail avec votre code cadeau ainsi que les instructions pour en bénéficier. Ce code cadeau donne droit à deux activités pour les vacances offertes à choisir sur le site <a href='https://www.france.rewardsforall.com' target='_blank'>www.france.rewardsforall.com</a> et il est valable jusqu’au 31 octobre 2023 inclus.",
                    "Par ailleurs, un quizz, disponible <a href='/'>ici</a>, vous sera proposé pour en découvrir davantage sur les régions de la France (jeu sans obligation d’achat et qui ne donne droit à aucune dotation).",
                    "La Fournée Dorée vous souhaite un très bel été !"
                ]
            ]
        ];

        let items = [];

        for (let i = 0; i < 3; i++) {

            items.push(
                <Carousel.Item key={"HowToItem" + i}>

                    <div className={"carousel_howto_item"} id={"carousel_howto_item" + (i + 1)}>
                        <div className={"picture"}/>
                        <div className={"content"}>
                            <h5>étape {i+1}</h5>
                            <h5 dangerouslySetInnerHTML={{__html : content[i][0]}}></h5>
                            {content[i][1].map(text => (<p dangerouslySetInnerHTML={{__html: text}}/>))}
                        </div>
                    </div>

                </Carousel.Item>
            );
        }

        return (
            <VisibilitySensor onChange={(isVisible) => {setIsCarouselHowToVisible(isVisible);}}>
                <div id={"howto_carousel_wrapper"}>
                    <Carousel controls={true} interval={isCarouselHowToVisible?7000:null} indicators={true}>
                        {items}
                    </Carousel>
                </div>
            </VisibilitySensor>
        );
    };
    const createCarouselDotation = () => {
        let items = [];
        const content = [
            [
                "Si vous préférez partir à l’aventure <br class='d-lg-none'/>pendant vos vacances, rendez-vous<br class='d-lg-none'/> chez nos partenaires à la montagne ou<br class='d-lg-none'/> en forêt pour faire de l’accrobranche,<br class='d-lg-none'/> des balades à cheval, du paintball, de la <br class='d-lg-none'/>randonnée, du tir-à-l’arc…",
                "N’oubliez pas votre gourde et votre<br class='d-lg-none'/> encas !",
            ],
            [
                "Rafraîchissez-vous pendant vos<br class='d-lg-none'/> vacances grâce à notre sélection<br class='d-lg-none'/> d’activités nautiques ! Au programme :<br class='d-lg-none'/> balades en bateau, canyoning, kayak,<br class='d-lg-none'/> paddle, parcs aquatiques…",
                "Il y en aura pour tous les goûts et tous<br class='d-lg-none'/> les âges !"
            ],
            [
                "Vous restez à la maison cet été ?<br class='d-lg-none'/> Pas d’inquiétude, nous vous proposons<br class='d-lg-none'/> un large choix d’activités à côté de chez<br class='d-lg-none'/> vous : ateliers divers, parcs à thème,<br class='d-lg-none'/> roller, visites culturelles…",
                "Vous trouverez forcément votre<br class='d-lg-none'/> bonheur chez l’un<br class='d-lg-none'/> de nos partenaires !"
            ]
        ];

        for (let i = 0; i < 3; i++) {

            items.push(
                <Carousel.Item key={"DotationItem" + i}>
                    <div className={"carousel_dotation_item"} id={"carousel_dotation_item" + (i + 1)}>
                        <div className={"picture"}></div>
                        <div className={"content"}>
                            {content[i].map(text => (<p dangerouslySetInnerHTML={{__html: text}}/>))}
                        </div>
                    </div>
                </Carousel.Item>
            );
        }

        return (
            <VisibilitySensor onChange={(isVisible) => {setIsCarouselDotationToVisible(isVisible);}}>
                <div id={"dotation_carousel_wrapper"}>
                    <Carousel controls={true} interval={isCarouselDotationToVisible?7000:null} indicators={true}>
                        {items}
                    </Carousel>
                </div>
            </VisibilitySensor>
        );
    };

    return (
        <>
            <div id={"homeStrate1"} className={"strate first"}>
                <Link to={"/je-participe"}>participer</Link>
            </div>

            <div id={"homeStrate2"} className={"strate"}>
                {createCarouselDotation()}

                <div id={"dotation_voyage_wrapper"}>
                    <div className={"picture"}></div>
                    <div className={"content"}>
                        <p>
                            pour 4 personnes (2 adultes et 2 <br className='d-lg-none'/>
                            enfants) en <br className='d-none d-lg-block'/>France Métropolitaine <br className='d-lg-none'/>
                            d’une valeur de 3 000€.<br className='d-none d-lg-block'/> De quoi vous <br className='d-lg-none'/>
                            faire plaisir en famille lors de vos <br/>
                            prochaines vacances !
                        </p>
                        <p>
                            Le tirage au sort sera effectué le 08/09/2023 parmi les <br/>
                            participations valides uniquement. Le séjour devra <br className='d-lg-none'/>
                            être réservé <br className='d-none d-lg-block'/>au plus tard le 29/02/2024 et effectué <br className='d-lg-none'/>
                            avant le 31/08/2024.
                        </p>
                    </div>
                </div>
            </div>

            <div id={"homeStrate3"} className={"strate"}>
                <h2>comment participer ?</h2>
                {createCarouselHowTo()}
            </div>

            <Contact/>
        </>
    );
};


export default Home;
